<template>
  <div class="mb-5">
    <TheCarousel :items="carousel.items"></TheCarousel>

    <v-container>
      <v-row class="section1" justify="center">
        <v-col cols="12">
          <h2 class="main-title">
            {{ mainList[0].title }}
          </h2>
          <div class="main-description">
            {{ mainList[0].content }}
          </div>
        </v-col>
      </v-row>

      <v-row class="section2" justify="center" align="center">
        <v-col cols="12">
          <h2 class="main-title">Our Achievement</h2>
        </v-col>
        <v-col cols="12">
          <v-row
            class="img-content"
            v-for="item in achievementList"
            :key="item.title"
            :class="item.class"
            align="center"
          >
            <v-col cols="6">
              <div class="content">
                <div class="content-title">{{ item.title }}</div>
                <div class="description">{{ item.content }}</div>
              </div>
            </v-col>
            <v-col class="img-block" cols="6">
              <v-img :transition="none"  height="560" contain :src="item.img"></v-img>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row class="section3" justify="center">
        <v-col cols="12">
          <h2 class="main-title">
            {{ mainList[1].title }}
          </h2>
          <div class="main-description">
            {{ mainList[1].content }}
          </div>
        </v-col>
      </v-row>
      <v-img :transition="none"  src="@/assets/home/Section.png"></v-img>
    </v-container>
  </div>
</template>

<script>
import TheCarousel from "@/components/TheCarousel.vue";
export default {
  components: {
    TheCarousel
  },
  data() {
    return {
      carousel: {
        items: [
          {
            img: require("@/assets/banners/home-page.jpg"),
            title: `We're a global business founded on
creativity, technology and innovation.`,
            subtitle: "A diverse global powerhouse",
            align: "center",
            justify: "start"
          }
        ]
      },
      mainList: [
        {
          title: "Who We Are",
          content: `Since its foundation in 2020,
TowerKing Tech Limited is dedicated to the development and innovation of
new technologies and software systems.`
        },
        {
          title: "Our Services",
          content: `TowerKing Tech Limited is a global entertainment content
technology company and a top technology solution provider.`
        }
      ],

      achievementList: [
        {
          title: "We Proud ",
          content:
            "We proud be a world leader in gaming content and technology, and a top-tier software service provider.",
          img: require("@/assets/home/achievement1.png")
        },
        {
          class: "left",
          title: "Diversified Enterprise",
          content:
            "We are a diversified enterprise, united by common values and inspiring mission.",
          img: require("@/assets/home/achievement2.png")
        },
        {
          title: "We are Committed",
          content: `We are committed to contributing our values to society,and to lift the bar in company governance,
employee wellbeing and sustainability.`,
          img: require("@/assets/home/achievement3.png")
        }
      ],
      tabs: {
        model: null,
        items: [
          {
            title: "Who we are",
            content: `Since its foundation in 2020, TowerKing Tech Limited is dedicated to the
development and innovation of new technologies and software systems.`
          },
          {
            title: "Our Philosophy/Our Achievement",
            content: `We’re proud to be a world leader in gaming content and technology, and a top-tier
publisher of free-to-play mobile games. We deliver great performance for our B2B
customers and bring joy to the lives of the millions of people who love to play our
products. And while we focus on fun, we never forget our responsibilities. We strive
to lead the way in responsible gameplay, and to lift the bar in company governance,
employee wellbeing and sustainability. We’re a diverse business united by shared
values and an inspiring mission to bring joy to life through the power of play.`
          },
          {
            title: "Our Services",
            content: `TowerKing Tech Limited is a global entertainment content and technology company and
mobile games publisher.
`,
            children: [
              {
                title: "Product Optimisation",
                content: `We will help you optimise your product
ensuring that the game works at the
same framerate across a wide range of
hardware specs.
`
              },
              {
                title: "Product Design",
                content: `We help with the design of your product,
helping you get the right balance on the
design and the aesthetics for your game`
              },
              {
                title: "Art Support",
                content: `We understand the importance of the Art
for any game, which is why we have the
ability to be hands-on for any support
you may require for your game.`
              },
              {
                title: "Tech Support",
                content: `We have a team of developers who can
provide technical support as we
understand how frustrating it came be
when a bug is found.`
              },
              {
                title: "Market Research",
                content: `Our marketing team has proven
experience marketing casual mobile
games for the global market. Let us help `
              },
              {
                title: "Localisation",
                content: `We will take care of the localisation of
your game ensuring we are creating an
enjoyable and non-confusing play
experience for the end user`
              }
            ]
          }
        ]
      }
    };
  }
};
</script>
<style lang="scss" scoped>
.section1 {
  margin-top:  toRem(70px);
}
.section2 {
  margin: 0 auto;
  max-width:  toRem(1500px);
  padding-top:  toRem(190px);

  .img-content {
    margin: 0 auto  toRem(90px);
    display: flex;
    flex-direction: row;
    &.left {
      flex-direction: row-reverse;
    }
  }

  .content {
    color: #101828;
    max-width:  toRem(512px);
    margin-left: auto;

    .content-title {
      font-weight: 600;
      font-size:  toRem(48px);
      line-height: 1;
      color: #101828;
    }
    .description {
      margin-top:  toRem(20px);
      font-weight: 400;
      font-size:  toRem(30px);
      line-height: 1.2;
      color: #101828;
    }
  }
}
.section3 {
  margin-top:  toRem(200px);
  margin-bottom:  toRem(200px);
}
// shared
.main-title {
  font-weight: 600;
  font-size:  toRem(72px);
  line-height:  toRem(124px);
  text-align: center;
  color: #175cd3;
}

.main-description {
  margin-top:  toRem(30px);
  font-weight: 600;
  font-size:  toRem(30px);
  line-height:  toRem(40px);
  text-align: center;
  color: #101828;
  white-space: pre-line;
}
</style>
